import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import { HTMLContent } from "./Content";

const Post = ({ post }) => {
  const PostContent = HTMLContent || post.excerpt;

  return (
    <article
      className={`blog-list-item  box  ${
        post.frontmatter.featuredpost ? "is-featured" : ""
      } m-b-mdd`}
    >
      <header>
        {/* {post.frontmatter.featuredimage ? (
         <div className="featured-thumbnail">
           <PreviewCompatibleImage
             imageInfo={{
               image: post.frontmatter.featuredimage,
               alt: `featured image thumbnail for post ${
                 post.title
               }`,
             }}
           />
         </div>
       ) : null}  */}
        <p className="post-meta">
          <Link
            className="title has-text-grey-dark is-size-5"
            to={post.fields.slug}
          >
            {post.frontmatter.title}
          </Link>
          <span></span>
          <span className="subtitle is-size-6 is-block has-text-grey">
            {post.frontmatter.date}
          </span>
        </p>
      </header>
      <p>
        <PostContent className="disable-links" content={post.excerpt} />
        <br />
        <Link className="button is-light" to={post.fields.slug}>
          Keep Reading →
        </Link>
      </p>
    </article>
  );
};

const PostsCol = ({ col, maxCols, posts }) =>
  posts
    .filter((post, index) => (index + maxCols - col + 1) % maxCols === 0)
    .map(({ node: post }) => <Post key={post.id} post={post} />);

const BlogRoll = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark;

  return (
    <div className="columns is-multiline">
      <div className="column is-3 is-hidden-touch">
        <PostsCol col={1} maxCols={4} posts={posts} />
      </div>
      <div className="column is-3 is-hidden-touch">
        <PostsCol col={2} maxCols={4} posts={posts} />
      </div>
      <div className="column is-3 is-hidden-touch">
        <PostsCol col={3} maxCols={4} posts={posts} />
      </div>
      <div className="column is-3 is-hidden-touch">
        <PostsCol col={4} maxCols={4} posts={posts} />
      </div>
      <div className="column is-6 is-tablet-only">
        <PostsCol col={1} maxCols={2} posts={posts} />
      </div>
      <div className="column is-6 is-tablet-only">
        <PostsCol col={2} maxCols={2} posts={posts} />
      </div>
      <div className="column is-12 is-hidden-tablet">
        <PostsCol col={1} maxCols={1} posts={posts} />
      </div>
    </div>
  );
};

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(format: HTML, pruneLength: 200)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "DD.MM.YYYY")
                featuredpost
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
);
